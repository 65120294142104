import { render, staticRenderFns } from "./edit-logistic-info.vue?vue&type=template&id=762c1ce5&scoped=true&"
import script from "./edit-logistic-info.ts?vue&type=script&lang=ts&"
export * from "./edit-logistic-info.ts?vue&type=script&lang=ts&"
import style0 from "./edit-logistic-info.scoped.scss?vue&type=style&index=0&id=762c1ce5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "762c1ce5",
  null
  
)

export default component.exports