// 本JS是加载Lodop插件及CLodop服务的综合示例，可直接使用，建议看懂后融进自己页面程序==

import { MessageBox } from 'element-ui';
import { translation } from '.';
export interface Lodop {
  /**
   * 打印初始化
   * @param name 打印名称
   */
  PRINT_INIT(name: string): void;
  /**
   * 添加要打印的html内容，关于长度单位，请参考http://www.lodop.net/demolist/PrintSample29.html
   * @param top 顶部距离
   * @param left 左边距离
   * @param width 宽度
   * @param height 高度
   * @param html html内容
   */
  ADD_PRINT_HTM(
    top: number | string,
    left: number | string,
    width: number | string,
    height: number | string,
    html: string
  ): void;

  /**
   * 设置纸张尺寸大小
   * @param intOrient 左边距离
   * @param width 宽度
   * @param height 高度
   * @param strPageName 尺寸名字
   */
  SET_PRINT_PAGESIZE(
    intOrient: number | string,
    width: number | string,
    height: number | string,
    strPageName: string
  ): void;

  /**
   * 设置打印样式
   * @param strStyleName 名称
   * @param varStyleValue 值
   */
  SET_PRINT_STYLE(strStyleName: string, varStyleValue: string | number): void;

  /**
   * 设置纸张尺寸大小
   * @param top 顶部距离
   * @param left 左边距离
   * @param width 宽度
   * @param height 高度
   * @param image 支持base64和img标签
   */
  ADD_PRINT_IMAGE(
    top: number | string,
    left: number | string,
    width: number | string,
    height: number | string,
    image: string
  ): void;

  /**
   * 添加要打印的html内容，与ADD_PRINT_HTM两者的差别，请参考https://www.cnblogs.com/ljj999/articles/LOPOP_HTM.html
   * @param top 顶部距离
   * @param left 左边距离
   * @param width 宽度
   * @param height 高度
   * @param html html内容
   */
  ADD_PRINT_HTML(
    top: number | string,
    left: number | string,
    width: number | string,
    height: number | string,
    html: string
  ): void;

  /**
   * 添加要打印的文本，关于长度单位，请参考http://www.lodop.net/demolist/PrintSample29.html
   * @param top 顶部距离
   * @param left 左边距离
   * @param width 宽度
   * @param height 高度
   * @param text 文本
   */
  ADD_PRINT_TEXT(top: number, left: number, width: number, height: number, text: string): void;
  /**
   * (增强型) 设置打印项风格A,继承SET_PRINT_STYLE的所有属性,二者的区别是本函数只对单个打印项有效。
   * @param varItemNameID
   * @param strStyleName
   * @param varStyleValue
   */
  SET_PRINT_STYLEA(varItemNameID: number, strStyleName: string, varStyleValue: number | string): void;
  /**
   * 打印预览
   */
  PREVIEW(): void;
  /**
   * 打印设置
   */
  PRINT_SETUP(): void;
  /**
   * 打印设计
   */
  PRINT_DESIGN(): void;
  /**
   * 执行打印
   */
  PRINT(): void;
  /**
   * 设置打印机
   * @param indexOrName 索引或名称，设置后不允许手动更改
   */
  SET_PRINTER_INDEX(indexOrName: number | string): void;
  /**
   * 设置打印机
   * @param indexOrName 索引或名称，设置后允许手动更改
   */
  SET_PRINTER_INDEXA(indexOrName: number | string): void;
}

let CLodopJsState: string;

/**
 * 加载引用CLodop的主JS,用双端口8000和18000(以防其中一个被占)
 */
export function loadCLodop(): void {
  if (CLodopJsState === 'loading' || CLodopJsState === 'complete') return;
  CLodopJsState = 'loading';
  const head = document.head || document.getElementsByTagName('head')[0] || document.documentElement;
  const JS1 = document.createElement('script');
  const JS2 = document.createElement('script');
  JS1.src = 'http://localhost:8000/CLodopfuncs.js?priority=1';
  JS2.src = 'http://localhost:18000/CLodopfuncs.js';
  JS1.onload = JS2.onload = function(): void {
    CLodopJsState = 'complete';
  };
  JS1.onerror = JS2.onerror = function(): void {
    CLodopJsState = 'complete';
  };
  head.insertBefore(JS1, head.firstChild);
  head.insertBefore(JS2, head.firstChild);
}

/**
 * 获取LODOP对象主过程
 * @returns LODOP对象或空（未安装或未加载完成时调用会返回空）
 */
export function getLodop(): Lodop | null {
  let LODOP;
  try {
    LODOP = window.getCLodop();
    if (!LODOP && CLodopJsState !== 'complete') {
      MessageBox.alert(translation('lodop.loading'));
      return null;
    }
    return LODOP;
  } catch (err) {
    MessageBox.confirm(translation('lodop.printUnInstallTip'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.download'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    })
      .then(() => {
        window.open('http://www.lodop.net/download.html');
      })
      .catch(() => {});
    return null;
  }
}
