import { electronicExpressWaybillService, logisticsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { DeliveryModeEnum, LogisticsOrderStatusEnum } from '@/resource/enum';
import { BatchEditLogisticsinfo, LogisticsDetail } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { telOrMobile } from '@/utils/validate';
import { DatePickerOptions } from 'element-ui/types/date-picker';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
type LogisticsAddressForm = Partial<BatchEditLogisticsinfo> & {
  deliveryArea?: Array<string>;
  receiveArea: Array<string>;
  childCount?: number;
};
@Component({
  components: {}
})
export default class EditLogisticInfo extends mixins(DialogMixin) {
  @Prop({ type: Object, required: true })
  public logistics!: LogisticsDetail;

  public form: Partial<LogisticsAddressForm> = {
    logisticsNo: '',
    receiver: '',
    receivingAddress: '',
    receivingTel: '',
    remark: '',
    sender: '',
    childCount: undefined,
    senderAddress: '',
    senderTel: '',
    sendMethod: DeliveryModeEnum.TPL,
    expressCom: '',
    deliveryArea: [],
    receiveArea: [],
    requiredArriveTime: ''
  };

  public formRules: {
    [p in keyof LogisticsAddressForm]: Array<object>;
  } = {
    sendMethod: [
      {
        required: true,
        validator: (rule: any, value: DeliveryModeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('logistics.selectDelivertMode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    receiver: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('logistics.inputReceiveUser')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    senderTel: [
      {
        required: false,
        validator: (rule: any, value: string, callback: Function): void => {
          if (value && !telOrMobile(value)) {
            callback(new Error(translation('validate.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receivingTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('logistics.inputReceivingTel')));
            return;
          }
          if (!telOrMobile(value)) {
            callback(new Error(translation('validate.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receiveArea: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('logistics.selectReceiveArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    requiredArriveTime: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('logistics.selectRequiredArriveTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    receivingAddress: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('logistics.inputReceiveAddress')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    childCount: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('logistics.inputChildCount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };
  public timeOptions: DatePickerOptions = {
    disabledDate: (date: Date) => {
      const callbackDateTime = date.getTime();
      const today = new Date();
      const currentDateTime = today.getTime();
      return callbackDateTime < currentDateTime;
    }
  };

  /**
   * 快递公司
   */
  public expressCompanys: Array<{ id: number; name: string; code: string }> = [];

  public deliveryModes: Array<{ label: string; value: string }> = [];

  /**
   * 是否第三方物流发货
   */
  public get isTPL(): boolean {
    return this.logistics?.sendMethod === DeliveryModeEnum.TPL;
  }

  /**
   * 物流单是否已发货
   */
  public get isDelivered(): boolean {
    return this.logistics?.status !== LogisticsOrderStatusEnum.new;
  }

  /**
   * 物流单是否已完成
   */
  public get isComplete(): boolean {
    return (
      this.logistics?.status === LogisticsOrderStatusEnum.closed ||
      this.logistics?.status === LogisticsOrderStatusEnum.received
    );
  }

  public created(): void {
    this.getExpressCompanys();
    this.deliveryModes = logisticsService.getDeliveryMode();
    Object.assign(this.form, this.logistics);
    if (this.logistics.receivingProvince) {
      this.form.receiveArea = [
        this.logistics.receivingProvince,
        this.logistics.receivingCity,
        this.logistics.receivingDistrict
      ].filter(item => !!item);
    }

    if (this.logistics.sendProvince) {
      this.form.deliveryArea = [
        this.logistics.sendProvince,
        this.logistics.sendCity,
        this.logistics.sendDistrict
      ].filter(item => !!item);
    }
  }

  /**
   * 获取物流公司
   */
  public getExpressCompanys(): void {
    electronicExpressWaybillService
      .getExpressCompanys()
      .then(res => {
        this.expressCompanys = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public submit(): void {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      [
        this.form.sendProvince = '',
        this.form.sendCity = '',
        this.form.sendDistrict = ''
      ] = this.form.deliveryArea!;
      [
        this.form.receivingProvince = '',
        this.form.receivingCity = '',
        this.form.receivingDistrict = ''
      ] = this.form.receiveArea!;

      logisticsService
        .batchEditLogisticsInfo(this.form as BatchEditLogisticsinfo)
        .then(() => {
          this.$emit('edit-info-success');
          this.closeDialog();
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {
          this.setLoading(false);
        });
    });
  }
}
